import { mapModalView } from 'DesignComponents/Organisms/Modal/ModalContentViews';
import { useUiState } from 'Shared/Providers/UiState/UiStateProvider';
import CountryPickerModalContainer from './CountryPickerModalContainer';
import CountryPickerModalFooter from './CountryPickerModalFooter';
import { mapModalFooterView } from 'DesignComponents/Organisms/Modal/ModalFooterViews';
import { ModalFooterLayoutOption } from 'Enums/ModalFooterLayout.enum';
import { styled } from '@stitches/react';
import { GetShippingDestination } from './ShippingDestination';

import { useTranslations } from '../../context/init-data.context';

function CountryPicker() {
  const {
    commonLabels: { deliveryTo, chooseCountry },
  } = useTranslations();

  const { modalState, toggleModal } = useUiState();
  const currentShippingDestination = GetShippingDestination();

  const modalHeader = chooseCountry;

  const onCountryPicker = (e?: React.MouseEvent<HTMLElement>) => {
    toggleModal(
      !modalState.display,
      'CountryPicker',
      modalHeader,
      {
        component: mapModalView<typeof CountryPickerModalContainer>({
          type: 'CountryPicker',
          props: {},
        }),
      },
      mapModalFooterView<typeof CountryPickerModalFooter>({
        type: 'CountryPickerModalFooter',
        props: {
          displayModal: !modalState.display,
          modalFooterContent: {
            footerLayout: ModalFooterLayoutOption.SIDE_BY_SIDE,
          },
        },
      }),
      e && (e.currentTarget as HTMLElement)
    );
  };

  return (
    <CountryPickerWrapper>
      {deliveryTo}:{' '}
      {currentShippingDestination?.name && (
        <Country onClick={onCountryPicker}>
          {currentShippingDestination.name}
        </Country>
      )}
    </CountryPickerWrapper>
  );
}

const CountryPickerWrapper = styled('div');

const Country = styled('button', {
  cursor: 'pointer',
  backgroundColor: 'transparent',
  fontFamily: 'fontBold',
  borderBottom: '1px solid',
});

export default CountryPicker;
