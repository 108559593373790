import FooterFeaturedAreaModel from 'Models/Footer/FooterFeaturedAreaModel.interface';
import { styled } from 'Theme/stitches.config';
import InteractableContentLink from 'Shared/Components/Links/InteractableContentLink';
import { Facebook, Instagram, LinkedIn } from 'DesignSystem/Icons';
import HtmlArea from 'DesignComponents/Molecules/XHtmlArea/XHtmlArea';
import CountryPicker from 'Commerce/CountryPicker/CountryPicker';

type PropTypes = {
  featuredArea: FooterFeaturedAreaModel;
};

const FeaturedArea = ({
  featuredArea: { heading, text, facebookLink, instagramLink, linkedInLink },
}: PropTypes) => {
  return (
    <FeaturedAreaWrapper>
      <FeatureHeaderWrapper>
        <SiteName>{heading}</SiteName>
      </FeatureHeaderWrapper>
      <Row>
        <CountryPicker />
      </Row>
      <HtmlArea content={text} css={styledHtmlArea} />
      <IconsWrapper>
        {facebookLink && facebookLink.href && (
          <InteractableContentLink href={facebookLink.href}>
            <Facebook size="s" color={'primary'} title="Facebook" />
          </InteractableContentLink>
        )}
        {instagramLink && instagramLink.href && (
          <InteractableContentLink href={instagramLink.href}>
            <Instagram size="s" color={'primary'} title="Instagram" />
          </InteractableContentLink>
        )}
        {linkedInLink && linkedInLink.href && (
          <InteractableContentLink href={linkedInLink.href}>
            <LinkedIn size="s" color={'primary'} title="LinkedIn" />
          </InteractableContentLink>
        )}
      </IconsWrapper>
    </FeaturedAreaWrapper>
  );
};

export default FeaturedArea;

const FeaturedAreaWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'start',
  flexDirection: 'column',
  '@bpMax960': {
    textAlign: 'center',
    '& img': {
      mx: 'auto',
    },
  },
});

const FeatureHeaderWrapper = styled('div', {
  mb: '$s75',
});

const SiteName = styled('div', {
  fontSize: '$fontSize500',
  fontFamily: 'fontBold',
});

const Row = styled('div', {
  mb: 4,
});

const IconsWrapper = styled('div', {
  mt: 8,
  display: 'flex',
  gap: '$s50',
  '@bpMax960': {
    justifyContent: 'center',
  },
  a: {
    borderRadius: '50%',
    border: '1px solid $onSurface',
    width: 32,
    height: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const styledHtmlArea = {
  fontSize: '$fontSize100',
  lineHeight: '$lh24',
  a: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      textDecorationThickness: '1px',
    },
  },
};
