import { GetCart } from 'Commerce/Cart/Cart';
import { useAppSettings } from '../../context/init-data.context';
import { useUserSession } from '../../context/session-data.context';

export const GetShippingDestination = () => {
  const { languageRoute } = useAppSettings();
  const { resolvedShippingDestination } = useUserSession();
  const { cart } = GetCart(languageRoute);
  return cart?.shippingDestination ?? resolvedShippingDestination;
};
