import loadable from '@loadable/component';
import { GetComponentProps } from 'Shared/Types/types';
import ModalFooter from '../Modal/ModalFooter';

type LoadableModalFooterViews<T extends string> = Record<T, React.ElementType>;
export type ModalFooterViewTypes = typeof ModalFooter;

export const ModalFooterViews = {
  ModalFooterView: loadable(
    () => import(/* webpackPrefetch: true */ '../Modal/ModalFooter')
  ),
  FilterModalFooter: loadable(
    () =>
      import(
        /* webpackPrefetch: true */ '../../../Commerce/Organisms/FilterComponent/FilterModalFooter'
      )
  ),
  MiniCartModalFooter: loadable(
    () =>
      import(
        /* webpackPrefetch: true */ '../../../Commerce/Cart/MiniCart/MiniCartModalFooter'
      )
  ),
  CountryPickerModalFooter: loadable(
    () =>
      import(
        /* webpackPrefetch: true */ '../../../Commerce/CountryPicker/CountryPickerModalFooter'
      )
  )
};

const loadableFooterViews: LoadableModalFooterViews<
  keyof typeof ModalFooterViews
> = ModalFooterViews;

export const mapModalFooterView = <T extends ModalFooterViewTypes>({
  props,
  type,
}: {
  type: keyof typeof loadableFooterViews;
  props: GetComponentProps<T>;
}) => {
  const ModalView = loadableFooterViews[type];
  return <ModalView {...(props as object)} />;
};
